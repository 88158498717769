import {createStore, applyMiddleware, compose} from 'redux';
import reducers from '../reducers';
import reduxThunk from 'redux-thunk';

// const composeEnhancers =
//   typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;
//
// const enhancer = composeEnhancers(
//   applyMiddleware(
//     routerMiddleware(history),
//     thunk,
//     generateId,
//     api,
//     logger
//   )
// );
//
// const store = createStore(reducer(history), enhancer)

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

export default store;
