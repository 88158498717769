import { FETCH_USER, SUCCESS, START, ERROR } from '../actions/types';

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  user: null
};

export default function auth(state = defaultsState, action) {
  // console.log(action.type)
  // console.log(action.payload)
  switch (action.type) {
    case FETCH_USER + START:
      return {
        loading: true,
        loaded: false,
        error: false,
        user: null
      };
    case FETCH_USER + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        user: action.payload || false
      };
    case FETCH_USER + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true,
        user: null
      };
    default:
      return state;
  }
}

