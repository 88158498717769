import {combineReducers} from 'redux';
import authReducer from './auth';
import campaignsReducer from './campaigns';
import transactionsReducer from './transactions';

export default combineReducers({
  auth: authReducer,
  campaigns: campaignsReducer,
  transactions: transactionsReducer,
});
