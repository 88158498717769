import { LOAD_CAMPAIGNS, START, SUCCESS, ERROR } from '../actions/types';

const defaultsState = {
  loading: false,
  loaded: false,
  error: false,
  entities: null
};
export default function campaigns(state = defaultsState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGNS + START:
      return {
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_CAMPAIGNS + SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: false,
        entities: action.payload || false
      };
    case LOAD_CAMPAIGNS + ERROR:
      return {
        loading: false,
        loaded: false,
        error: true
      };
    default:
      return state;
  }
}

