const SMP_PATH = '/social-media-platforms';
const MC_PATH = '/marketing-campaigns';
const FAQ_PATH = '/faq';
const ABOUT_PATH = '/about';
const TERMS_PATH = '/terms-and-conditions';
const PRIVACY_POLICY_PATH = '/privacy-policy';
const GDPR_PATH = '/gdpr';
const LOGIN_PATH = '/login';
const SIGN_UP_PATH = '/sign-up';
const PASSWORD_RECOVERY_PATH = '/password-recovery';
const NEW_PASSWORD_PATH = '/reset-password';
const CONTACT_US_PATH = '/contact-us';
const WE_CARE_PATH = '/we-care';
const DASHBOARD_PATH = '/dashboard';
const DASHBOARD_CAMPAIGNS_PATH = '/dashboard/my-campaigns';
const DASHBOARD_SETTINGS_PATH = '/dashboard/settings';
const DASHBOARD_CAMPAIGN_1 = '/dashboard/organic-distribution-campaign';
const DASHBOARD_CAMPAIGN_2 = '/dashboard/content-creation-distribution-campaign';
const DASHBOARD_CAMPAIGN_3 = '/dashboard/create-social-media-content-campaign';
const DASHBOARD_CAMPAIGN_4 = '/dashboard/free-humanitarian-campaign';
const TWITTER_LINK = 'https://twitter.com/verbyoapp';
const INSTAGRAM_LINK = 'https://www.instagram.com/verbyo';
const FACEBOOK_LINK = 'https://m.me/verbyo';
const DASHBOARD_REDIRECT = LOGIN_PATH;

const CAMPAIGN_CREATE_IDS = {
  choosePlatform: 'choose-platform',
  campaignDetails: 'campaign-details',
  defineAudience: 'define-audience',
  createScript: 'create-script',
  adRequirements: 'ad-requirements',
  budgetPayment: 'budget-payment',
  designAdd: 'design-add',
  contentDetails: 'content-details'
};

const PLATFORMS = ['instagram', 'facebook', 'twitter', 'tiktok'];

// const API = 'http://localhost:3000/api';
const API = '/api';

module.exports = {
  SMP_PATH,
  MC_PATH,
  FAQ_PATH,
  ABOUT_PATH,
  TERMS_PATH,
  PRIVACY_POLICY_PATH,
  GDPR_PATH,
  LOGIN_PATH,
  SIGN_UP_PATH,
  PASSWORD_RECOVERY_PATH,
  NEW_PASSWORD_PATH,
  CONTACT_US_PATH,
  WE_CARE_PATH,
  DASHBOARD_PATH,
  DASHBOARD_CAMPAIGNS_PATH,
  DASHBOARD_SETTINGS_PATH,
  DASHBOARD_CAMPAIGN_1,
  DASHBOARD_CAMPAIGN_2,
  DASHBOARD_CAMPAIGN_3,
  DASHBOARD_CAMPAIGN_4,
  DASHBOARD_REDIRECT,
  TWITTER_LINK,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  CAMPAIGN_CREATE_IDS,
  PLATFORMS,
  API
};
