import {FETCH_USER, LOAD_CAMPAIGNS, LOAD_TRANSACTIONS, START, SUCCESS, ERROR} from './types';
import campaigns from '../mock/campaigns.json';
import transactions from '../mock/transactions.json';
import { getUser } from '../services/userAuthService';

export const fetchUser = () => async dispatch => {
  dispatch({type: FETCH_USER + START});
  getUser()
    .then(data => {
      console.log(data)
      if(!data || data.error) {
        dispatch({type: FETCH_USER + ERROR});
      } else {
        dispatch({type: FETCH_USER + SUCCESS, payload: {id: data.id}});
      }
    })
    .catch(e => {
      console.log(e);
      dispatch({type: FETCH_USER + ERROR});
    })
};

export const setLoginUserData = (data, rememberMe) => async dispatch => {
  const {token, id} = data;

  if(rememberMe) {
    localStorage.setItem('token', token);
    localStorage.setItem('jwt-token', token);

  } else {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('jwt-token', token);
  }
  dispatch({type: FETCH_USER + SUCCESS, payload: {id}});
};

export const logoutUser = () => async dispatch => {
  dispatch({type: FETCH_USER + SUCCESS, payload: false});

  localStorage.removeItem('token');
  localStorage.removeItem('jwt-token');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('jwt-token');
};

export const fetchCampaigns = () => async dispatch => {
  dispatch({type: LOAD_CAMPAIGNS + START});
  // const res = await axios.get('/api/current_user');
  // return Promise.resolve(campaigns);

  // const result = Promise.resolve(campaigns);
  // fetch(campaigns)
    // .then((response) => {
    //   return response.json();
    // })
    // .then((data) => {
    //   console.log(data);
    // });
  // console.log(result)
  // result.then(response)
  //
  dispatch({type: LOAD_CAMPAIGNS + SUCCESS, payload: campaigns});
};

export const fetchTransactions = () => async dispatch => {
  dispatch({type: LOAD_TRANSACTIONS + START});

  dispatch({type: LOAD_TRANSACTIONS + SUCCESS, payload: transactions});
};
