import { UserAuthApi } from '../api/userAuth';

export function registerNewUser({email, password, newsletter}) {
  return UserAuthApi.register({email, password, newsletter});
}

export function login({email, password}) {
  return UserAuthApi.login({email, password});
}

export function forgotPassword({email}) {
  return UserAuthApi.forgotPassword({email});
}

export function resetPassword({password, repassword, token}) {
  return UserAuthApi.resetPassword({password, repassword, token});
}

export function getUser() {
  return UserAuthApi.self();
}
