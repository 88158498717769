export function buildHeaders(withoutType) {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  const headers = {};

  if(!withoutType) headers['Content-Type'] = 'application/json';

  if (token) headers['Authorization'] = token;

  return headers;
}
