import React from 'react';

import styles from './loadings.module.scss';

export default function Loading() {
  return (
    <div className={styles.loading}>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  );
}
