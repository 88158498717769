import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CloseButton from '../CloseButton/CloseButton';
import styles from './modal.module.scss';

function Modal({children, isShown, onClose, desktopDisable, location}) {
  const html = document.documentElement;
  const body = document.body;
  const path = location.pathname;

  const showModal = () => {
    body.setAttribute('style', 'overflow-y: hidden');
    html.setAttribute('style', 'overflow-y: hidden');
  };

  const closeModal = () => {
    body.setAttribute('style', 'overflow-y: initial');
    html.setAttribute('style', 'overflow-y: initial');

    onClose();
  };

  useEffect(() => {
    closeModal();
  }, [path]);

  useEffect(() => {
    isShown && showModal();

  }, [isShown]);


  return (
    <div className={cx(styles.modal, isShown && styles.shown, desktopDisable && styles.desktopDisable)}>
      <CloseButton onClick={closeModal} desktopHide={desktopDisable}/>
      {children}
    </div>
  );
}

Modal.defaultProps = {
  desktopDisable: false,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  isShown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  desktopDisable: PropTypes.bool,
};

export default withRouter(Modal);
