import {
  DASHBOARD_PATH,
  DASHBOARD_CAMPAIGNS_PATH,
  DASHBOARD_SETTINGS_PATH,
  DASHBOARD_CAMPAIGN_1,
  DASHBOARD_CAMPAIGN_2,
  DASHBOARD_CAMPAIGN_3,
  DASHBOARD_CAMPAIGN_4,
} from '../globals';

const names = {
  [DASHBOARD_PATH]: 'Dashboard',
  [DASHBOARD_CAMPAIGNS_PATH]: 'My Campaigns',
  [DASHBOARD_SETTINGS_PATH]: 'Settings',
  [DASHBOARD_CAMPAIGN_1]: 'Create organic campaign',
  [DASHBOARD_CAMPAIGN_2]: 'Create content creation & distribution campaign',
  [DASHBOARD_CAMPAIGN_3]: 'Create social media content',
  [DASHBOARD_CAMPAIGN_4]: 'Create humanitarian campaign',
};

export default names;
