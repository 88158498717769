import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from '../Modal/Modal';
import globals from '../../globals';
import styles from './menu.module.scss';
import { logoutUser } from '../../actions';
import { authSelectorIsUserLogin } from '../../selectors';

function Menu({location, isLogin, isMenuShown, toggleMenu, logoutUser}) {
  let path = location.pathname;
  let dashboardMenu = false;

  if (path.slice(path.length - 1) === '/') {
    path = path.slice(0, path.length - 1)
  }

  if (path === globals.DASHBOARD_PATH
    || path === globals.DASHBOARD_CAMPAIGNS_PATH
    || path === globals.DASHBOARD_CAMPAIGN_1
    || path === globals.DASHBOARD_CAMPAIGN_2
    || path === globals.DASHBOARD_CAMPAIGN_3
    || path === globals.DASHBOARD_CAMPAIGN_4
    || path === globals.DASHBOARD_SETTINGS_PATH) {
    dashboardMenu = true;
  }

  const loginMenu = () => {
    if (isLogin) {
      return (
        <li className={styles.logout}>
          <button onClick={logoutUser}>Log Out</button>
        </li>
      )
    } else {
      return (
        <>
          <li className={path === globals.LOGIN_PATH ? styles.menuActive : ''}>
            <Link to={globals.LOGIN_PATH}>
              Log In
            </Link>
          </li>
          <li className={path === globals.SIGN_UP_PATH ? styles.menuActive : ''}>
            <Link to={globals.SIGN_UP_PATH}>
              Sign Up
            </Link>
          </li>
        </>
      )
    }
  };

  return (
    <Modal isShown={isMenuShown} onClose={toggleMenu} desktopDisable={true}>
      <ul className={styles.menu}>
        {!dashboardMenu ? (
          <>
            <li className={path === '/' || path === '' ? styles.menuActive : ''}>
              <Link to='/'>
                Home
              </Link>
            </li>
            <li className={path === globals.SMP_PATH ? styles.menuActive : ''}>
              <Link to={globals.SMP_PATH}>
                Social Media Platforms
              </Link>
            </li>
            <li className={path === globals.MC_PATH ? styles.menuActive : ''}>
              <Link to={globals.MC_PATH}>
                Marketing Campaigns
              </Link>
            </li>
            <li className={path === globals.WE_CARE_PATH ? styles.menuActive : ''}>
              <Link to={globals.WE_CARE_PATH}>
                #WeCare
              </Link>
            </li>
            <li className={path === globals.ABOUT_PATH ? styles.menuActive : ''}>
              <Link to={globals.ABOUT_PATH}>
                VerbyoApp
              </Link>
            </li>
            <li className={path === globals.FAQ_PATH ? styles.menuActive : ''}>
              <Link to={globals.FAQ_PATH}>
                FAQ's
              </Link>
            </li>

            {loginMenu()}
          </>
        ) : (
          <>
            <li className={path === globals.DASHBOARD_PATH ? styles.menuActive : ''}>
              <Link to={globals.DASHBOARD_PATH}>
                Dashboard
              </Link>
            </li>
            <li className={path === globals.DASHBOARD_CAMPAIGNS_PATH ? styles.menuActive : ''}>
              <Link to={globals.DASHBOARD_CAMPAIGNS_PATH}>
                My Campaigns
              </Link>
            </li>
            <li className={path === globals.DASHBOARD_SETTINGS_PATH ? styles.menuActive : ''}>
              <Link to={globals.DASHBOARD_SETTINGS_PATH}>
                Settings
              </Link>
            </li>
            {loginMenu()}
          </>
        )}
      </ul>
      <ul className={styles.menuBottom}>
        <li className={path === globals.TERMS_PATH ? styles.menuBottomActive : ''}>
          <Link to={globals.TERMS_PATH}>
            Terms & Conditions
          </Link>
        </li>
        <li className={path === globals.PRIVACY_POLICY_PATH ? styles.menuBottomActive : ''}>
          <Link to={globals.PRIVACY_POLICY_PATH}>
            Privacy Policy
          </Link>
        </li>
        <li className={path === globals.GDPR_PATH ? styles.menuBottomActive : ''}>
          <Link to={globals.GDPR_PATH}>
            GDPR
          </Link>
        </li>
        <li className={path === globals.CONTACT_US_PATH ? styles.menuBottomActive : ''}>
          <Link to={globals.CONTACT_US_PATH}>
            Contact Us
          </Link>
        </li>
      </ul>
    </Modal>
  );
}

Menu.defaultProps = {
  isLogin: false,
};

Menu.propTypes = {
  isLogin: PropTypes.bool,
  isMenuShown: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  isLogin: authSelectorIsUserLogin(store),
});

const mapDispatchToProps = {
  logoutUser: logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
