export const authSelectorLoaded = (store) => store.auth.loaded;
export const authSelectorLoading = (store) => store.auth.loading;
export const authSelectorUser = (store) => store.auth ? store.auth.user : null;
export const authSelectorIsUserLogin = (store) => store.auth && !!store.auth.user;

export const campaignsSelectorLoaded = (store) => store.campaigns.loaded;
export const campaignsSelectorLoading = (store) => store.campaigns.loading;
export const campaignsSelector = (store) => store.campaigns ? store.campaigns.entities : null;

export const transactionsSelectorLoaded = (store) => store.transactions.loaded;
export const transactionsSelectorLoading = (store) => store.transactions.loading;
export const transactionsSelector = (store) => store.transactions ? store.transactions.entities : null;
