import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import MenuButton from '../MenuButton/MenuButton';
import styles from './header.module.scss';
import globals from '../../globals';
import names from '../../data/pageNames';

const Header = ({location, isSticky}) => {
  const [menuShown, setMenuShown] = useState(false);
  const path = location.pathname;
  let hideHeaderDesktop = false;
  let showPageTitle = false;

  if(path === globals.SIGN_UP_PATH
    || path === globals.LOGIN_PATH
    || path === globals.CONTACT_US_PATH
    || path === globals.PASSWORD_RECOVERY_PATH) {
    hideHeaderDesktop = true;
  }

  if(path === globals.DASHBOARD_PATH
    || path === globals.DASHBOARD_CAMPAIGNS_PATH
    || path === globals.DASHBOARD_CAMPAIGN_1
    || path === globals.DASHBOARD_CAMPAIGN_2
    || path === globals.DASHBOARD_CAMPAIGN_3
    || path === globals.DASHBOARD_CAMPAIGN_4
    || path === globals.DASHBOARD_SETTINGS_PATH)  {
    showPageTitle = true;
  }

  const showMenu = () => {
    setMenuShown(true);
  };

  const closeMenu = () => {
    setMenuShown(false);
  };

  return (
    <header className={cx(styles.headerWrapper, hideHeaderDesktop && styles.hideHeaderDesktop, isSticky && styles.isSticky)}>
      <div className="wrapper wrapper--v3">
        <nav className={styles.header}>
          <MenuButton onClick={showMenu}/>

          <Link to='/' className={styles.headerLogo}>
            <Logo/>
          </Link>

          {showPageTitle && names[path] && (
            <h1 className={styles.headerTitle}>
              {names[path]}
            </h1>
          )}

          <Menu isMenuShown={menuShown} toggleMenu={closeMenu}/>
        </nav>
      </div>

    </header>
  )
};

Header.defaultProps = {
  isSticky: false,
};

Header.propTypes = {
  isSticky: PropTypes.bool,
};

export default withRouter(Header);
