import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import PropTypes from 'prop-types';

import * as actions from './actions';
import globals from './globals';
import Header from './components/Header/Header';

import Loading from './components/Loading/Loading';
import './app.scss';
import styles from './styles/main.module.scss';
import { debounce } from './utils/debounce';
import { authSelectorLoading } from './selectors';

const About = lazy(() => import('./pages/about'));
const ContactUs = lazy(() => import('./pages/contactUs'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const CreateSocialMediaContentCampaign = lazy(() => import('./pages/campaigns/createSocialMediaContent'));
const ContentCreationDistribution = lazy(() => import('./pages/campaigns/contentCreationDistribution'));
const FreeHumanitarianCampaign = lazy(() => import('./pages/campaigns/freeHumanitarian'));
const MyCampaigns = lazy(() => import('./pages/dashboard/my-campaigns'));
const FAQ = lazy(() => import('./pages/faq'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Home = lazy(() => import('./pages/home'));
const GDPR = lazy(() => import('./pages/gdpr'));
const Login = lazy(() => import('./pages/login'));
const MarketingCampaigns = lazy(() => import('./pages/marketingCampaigns'));
const OrganicDistribution = lazy(() => import('./pages/campaigns/organicDistribution'));
const Page404 = lazy(() => import('./pages/page404'));
const PasswordRecovery = lazy(() => import('./pages/passwordRecovery'));
const NewPassword = lazy(() => import('./pages/newPassword'));
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy'));
const SignUp = lazy(() => import('./pages/signUp'));
const Settings = lazy(() => import('./pages/dashboard/settings'));
const SocialMediaPlatforms = lazy(() => import('./pages/socialMediaPlatforms'));
const Terms = lazy(() => import('./pages/terms'));
const WeCare = lazy(() => import('./pages/weCare'));

function _ScrollToTop(props) {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

function App({userLoading, fetchUser}) {
  const desktopWidth = 1152;
  const isDesktop = () => window.innerWidth >= desktopWidth;
  const [desktop, setDesktop] = useState(isDesktop());

  let headerHeight = 0;
  const setHeight = (height) => {
    headerHeight = height;
  };

  useEffect(() => {
    fetchUser();

    const debouncedHandleResize = debounce(function handleResize() {
      setDesktop(isDesktop());
    }, 500);

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <Router>
      {userLoading ? (
        <Loading/>
      ) : (
        <Suspense fallback={<Loading/>}>
          <ScrollToTop>
            <StickyContainer className={styles.stickyContainer}>
              {desktop ? (
                <Sticky>
                  {({
                      style,
                      distanceFromTop,
                      calculatedHeight
                    }) => {
                    calculatedHeight && setHeight(calculatedHeight);
                    return (
                      <div style={{...style, zIndex: 10}}>
                        <Header isSticky={-distanceFromTop > calculatedHeight}/>
                      </div>
                    )
                  }}
                </Sticky>
              ) : (
                <Header/>
              )}
              <main className={styles.main}>
                <Switch>
                  <Route path="/" exact component={Home}/>
                  <Route path={globals.SMP_PATH} exact component={SocialMediaPlatforms}/>
                  <Route path={globals.MC_PATH} exact component={MarketingCampaigns}/>
                  <Route path={globals.FAQ_PATH} exact component={FAQ}/>
                  <Route path={globals.ABOUT_PATH} exact component={About}/>
                  <Route path={globals.PRIVACY_POLICY_PATH} exact component={PrivacyPolicy}/>
                  <Route path={globals.TERMS_PATH} exact component={Terms}/>
                  <Route path={globals.GDPR_PATH} exact component={GDPR}/>
                  <Route path={globals.SIGN_UP_PATH} exact component={SignUp}/>
                  <Route path={globals.CONTACT_US_PATH} exact component={ContactUs}/>
                  <Route path={globals.WE_CARE_PATH} exact component={WeCare}/>
                  <Route path={globals.PASSWORD_RECOVERY_PATH} exact component={PasswordRecovery}/>
                  <Route path={`${globals.NEW_PASSWORD_PATH}/:token`} component={NewPassword}/>
                  <Route path={globals.LOGIN_PATH} component={Login} />
                  <Route path={globals.DASHBOARD_PATH} exact component={Dashboard}/>
                  <Route path={globals.DASHBOARD_CAMPAIGN_1} exact component={OrganicDistribution}/>
                  <Route path={globals.DASHBOARD_CAMPAIGN_2} exact component={ContentCreationDistribution}/>
                  <Route path={globals.DASHBOARD_CAMPAIGN_3} exact component={CreateSocialMediaContentCampaign}/>
                  <Route path={globals.DASHBOARD_CAMPAIGN_4} exact component={FreeHumanitarianCampaign}/>
                  <Route path={globals.DASHBOARD_CAMPAIGNS_PATH} exact
                         render={() => <MyCampaigns scrollTop={() => headerHeight}/>}/>
                  <Route path={globals.DASHBOARD_SETTINGS_PATH} exact component={Settings}/>
                  <Route component={Page404}/>
                </Switch>

              </main>
            </StickyContainer>
          </ScrollToTop>
          <Footer/>
        </Suspense>
      )}
    </Router>
  );
}

App.propTypes =  {
  userLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  userLoading: authSelectorLoading(store),
});

export default connect(mapStateToProps, actions)(App);
