// import qs from 'qs';

import { API } from '../globals';
import { buildHeaders } from './utils';
// export const handleToken = (status) => {
//   const parsedToken = qs.parse(window.location.search, { ignoreQueryPrefix: true }).token;
//   if (parsedToken && (status === constitutionResponse.SUCCESS
//     || status === constitutionResponse.ALREADY_VOTED)) {
//     const oldTokens = localStorage.getItem('old-token') || '';
//     const token = localStorage.getItem('token') || '';
//     localStorage.setItem(
//       'old-token',
//       oldTokens ? `${oldTokens},${token}` : token,
//     );
//     localStorage.setItem('token', parsedToken);
//   }
// };

// function buildHeaders(initialToken, contentType) {
//   const oldTokens = localStorage.getItem('old-token');
//   const token = initialToken || qs.parse(window.location.search, { ignoreQueryPrefix: true }).token || localStorage.getItem('token');
//
//   const headers = {
//     'x-token': token,
//     'Content-Type': contentType || 'application/json',
//   };
//   if (oldTokens) headers['x-old-tokens'] = oldTokens;
//
//   return headers;
// }

// app.post('/api/surveys', requireLogin, requireCredits, async (req, res) => {
//   const { title, subject, body, recipients } = req.body;
//
//   const survey = new Survey({
//     title,
//     subject,
//     body,
//     recipients: recipients.split(',').map(email => ({ email })),
//     _user: req.user.id,
//     dateSent: Date.now()
//   });
//
//   // send an email
//   const mailer = new Mailer(survey, surveyTemplate(survey));
//
//   try {
//     await mailer.send();
//     await survey.save();
//
//     req.user.credits -= 1;
//     const user = await req.user.save();
//
//     res.send(user);
//   } catch (e) {
//     res.status(422);
//   }
//
// });



export const UserAuthApi = {
  register: (data) => {
    return fetch(`${API}/user/register-new-user`, {
      method: 'post',
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => data)
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  login: (data) => {
    return fetch(`${API}/user/login`, {
      method: 'post',
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => data)
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  forgotPassword: (data) => {
    return fetch(`${API}/user/forgot-password`, {
      method: 'post',
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => data)
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  resetPassword: (data) => {
    return fetch(`${API}/user/reset-password`, {
      method: 'post',
      headers: buildHeaders(),
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => data)
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  self: () => {
    return fetch(`${API}/user/self`, {
      method: 'get',
      headers: buildHeaders(),
    })
      .then(response => {
        if(!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.json()
        }
      })
      .then(data => data)
      .catch((error) => {
        console.error('Error:', error);
      });
  }
};
